<template>
  <b-row>
    <b-col v-for="(item, index) in senatorList" :key="index" md="6" lg="4">
      <iq-card>
        <template v-slot:body>
          <div @click="
              $router.push({
                path: '/profile',
                query: { user_id: item.user_id },
              })
            " class="d-flex align-items-center" style="cursor: pointer">
            <div class="avatar_senator mr-3">
              <img :src="item.avatar" class="rounded-circle img-fluid avatar-80 cursor-pointer" />
            </div>
            <div class="senator_name text-overflow-single">
              <h3 class="text-overflow-single widthRegular">
                {{ item.nick_name }}
              </h3>
              <p class="mb-0 mt-2">{{ item.senator_number }}</p>
            </div>
          </div>
          <div>
            <b-button class="mt-4 selectCheck" v-if="item.is_friend" @click="confirmDel(item.user_id)">
              <i class="ri-check-line"></i> {{$t('Senator.598@friend')}}
            </b-button>
            <!-- @click="friendRequest(item.user_id)" -->
            <b-button v-else class="mt-4" variant="primary" @click="friendRequest(item.user_id)">
              <i class="ri-add-line"></i>{{$t('Senator.598@friend')}}
            </b-button>
          </div>
        </template>
      </iq-card>
    </b-col>
  </b-row>
</template>

<script>
  export default {
    props: {
      senatorList: {
        type: Array,
      },
    },

    data() {
      return {};
    },

    components: {},

    created() {},

    methods: {
      // 添加好友
      friendRequest(user_id) {
        const data = {
          user_id
        };
        this.$http.friendRequest(data).then((res) => {
          if (res.status == 200) {}
        });
      },
      // 删除好友
      delFriend(user_id) {
        this.$http.delFriend({
          user_id
        }).then((res) => {
          if (res.status == 200) {}
        });
      },
      // 删除好友关系时询问
      confirmDel(user_id) {
        this.$confirm(this.$t('Senator.596@are_you_sure_you_wan'), this.$t('Senator.516@tips'), {
            distinguishCancelAndClose: true,
            confirmButtonText: this.$t('Senator.471@comfirm'),
            cancelButtonText: this.$t('Senator.Cancel'),
            type: "warning",
          })
          .then(() => {
            this.delFriend(user_id);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: this.$t('Senator.5e0@deletion_cancelled')
            });
          });
      },
    },
  };

</script>
<style lang="scss" scoped>
@media (min-width: 600px) {
  .widthRegular{
    width: 160px;
  }
}
@media (max-width: 600px) {
  .widthRegular{
    width: 200px;
  }
}

  .senator_name {
    >h3 {
      font-size: 18px;
      color: #333;
      cursor: pointer;

      >p {
        color: #999;
      }
    }
  }

  .selectCheck {
    height: 40px;
    pointer-events: none;
    cursor: default;
    background: #a09e9e;
  }

</style>
